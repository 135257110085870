import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [2];

export const dictionary = {
		"/system": [8,[3],[4]],
		"/system/invitations/[invitationHash]": [~9,[3],[4]],
		"/system/login": [10,[3],[4]],
		"/system/projects": [11,[3,5],[4]],
		"/system/projects/[projectId]": [12,[3,5,6],[4]],
		"/system/projects/[projectId]/access": [~13,[3,5,6],[4]],
		"/system/projects/[projectId]/applications": [14,[3,5,6],[4]],
		"/system/projects/[projectId]/applications/[appId]": [15,[3,5,6],[4]],
		"/system/projects/[projectId]/types": [16,[3,5,6],[4]],
		"/system/projects/[projectId]/types/[typeId]": [17,[3,5,6],[4]],
		"/system/register": [18,[3],[4]],
		"/[...page]": [7,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';